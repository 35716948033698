/* eslint-disable camelcase */
import './servicedetails.css';
import { Field, reduxForm } from 'redux-form';
import { checkSimPattern, isEmpty } from '../../../adapters/validations';
import { getUiState } from '../../../adapters/store-utils';
import Parser from 'html-react-parser';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
    SERVER_ERROR,
    PACKAGE_NOT_AVAILABLE,
    SIM_IN_USE_ERROR,
    SIM_PRE_CONDITION_NO_MATCH_ERROR,
    INVALID_SIM,
    ORDER_IN_PROGRESS,
    SIM_IS_UNSOLD,
    SIM_ORDER_COMPLETED,
    SIM_ORDER_INPROGRESS,
    NETWORK_FAILURE
} from '../../../adapters/errorCode';
import { getSerialNumber, isSelectedDeviceMobile, nextPage, getCurrentApplicationPath, submitFailure, focusOnElement, isOutageWindow, applyBrandDataInTemplate, getBrandDetails, getAbleSpriteSheet } from '../../../adapters/utils';
import PPVModal from '../PPVModal';
import WELCOME_LOCALE from '../../../assets/locale/v1/welcome';
import FullPageError from '../../../components/FullPageError/FullPageError';
import SpinningButton from '../../../components/SpinningButton/SpinningButton';
import Auth from '../Auth/auth';
import { redirectToMyTelstra } from '../../../adapters/esim-utils';
import { addDataLayerUser, addDataLayerEventInfo } from '../../../adapters/analytics-utils';
import { userProfileInfoMeta, BRANDS } from '../../../adapters/constants';
import GlobalBanner from '../../../common/components/globalBanner/globalBanner';
import { RadioGroup, MessageSection, TextStyle, Spacing } from '@able/react';
import RadioButton from '../../../components/RadioButton';
import TextField from '../../../components/TextField/TextField';
import esimImage from '../../../assets/img/esim-Image.svg';
import cardVisual from '../../../assets/img/cardvisual.png';

const ableSpriteSheet = getAbleSpriteSheet();

/**
 * Service details stateless component
 *
 * @param {any} { ...props }
 * @returns
 */
const ServiceDetails = ({ ...props }) => {
    const { loggedIn = false } = getUiState();
    const currentRouteName = getCurrentApplicationPath();
    const showCustomerRouteName = ['/welcome'];
    const showCustomer = showCustomerRouteName.indexOf(currentRouteName) !== -1 && loggedIn;
    // global state
    const {
        handleSubmit,
        actions: { resetErrors, triggerPortingModal },
        showPortingModal
    } = props;
    const {
        app: { hasErrorOccurred = false, loginCheckInProgress },
        appData: {
            uiState: { selectedDevice, selectedActivationFlow, isEsimActivation = false },
            isOdaFlow,
            serviceNumber: { failedAttempts = 0 },
            serviceNumber = {}
        }
    } = props;

    const {
        PPA_OUTAGE_NOTIFICATION_DISPLAY = 'false',
        PPA_OUTAGE_NOTIFICATION_MESSAGE = '',
        PPA_OUTAGE_NOTIFICATION_START_TIME = '',
        PPA_OUTAGE_NOTIFICATION_END_TIME = ''
    } = window.appConfig || {};
    // state validations
    const {
        validations: { isValidatingNumber, isNumberChecked, isFetchingCustomerData, simIdIsSerialNumber = false }
    } = props;
    // errors
    const { errors } = props;

    const {
        formData: { deviceTypeSelect, selectedFlow, yourPhoneNumberLabel },
        simValidationForm: {
            syncErrors: {
                simNumber: simNumberError = ''
            } = {}
        } = {}
    } = props;
    let isJBHiFi = false;
    let priceRise = false;

    const {
        deviceData: { deviceOffers, watch: isKidsWatchSim = false, simVersion = '' },
        areMultipleDevicesAvailable
    } = props;
    deviceOffers && window.localStorage && window.localStorage.removeItem('parameters');
    let outageDisplay;
    if (PPA_OUTAGE_NOTIFICATION_DISPLAY === 'true') {
        outageDisplay = isOutageWindow(PPA_OUTAGE_NOTIFICATION_START_TIME, PPA_OUTAGE_NOTIFICATION_END_TIME);
    }

    // local validation
    const disableCheckButton = isFetchingCustomerData || outageDisplay;
    const disableSimIdField = isNumberChecked || disableCheckButton || outageDisplay;

    const isDeviceTypeMobile = isSelectedDeviceMobile(selectedDevice);
    // const isProvidedNumberFlow = isDeviceTypeMobile && activationFlowFormValue === 'providedNumber';
    // const showNewNumber = selectedDevice && (selectedDevice === 'Telstra-Prepaid-Wireless-Broadband' || isProvidedNumberFlow);
    // const wrapperProps = { title: props.title, bannerImage: props.pageImage, altText: props.imageAltText, titleAltStart: props.titleAltStart, telstraAuthCustomerProfile };
    // const imageModalProps = { bannerImage: props.pageImage, altText: props.imageAltText };
    // In ODA flow, replace field with sanitized sim number
    if (isOdaFlow && isNumberChecked) {
        const { packageData: { simSerial = '' } = {} } = serviceNumber;
        const simNumber = getSerialNumber(simSerial);
        props.change('simNumber', simNumber);
    }

    // const btnText = isNumberChecked ? 'Continue' : 'Check serial number';
    const headerText = 'Let’s get started!';
    const deviceTypeLable = deviceTypeSelect.label;

    let errorProps = {
        errorMessage: 'We’re unable to process your request at this time. Please try again later. ',
        errorText: 'Something went wrong'
    };

    if (failedAttempts + 1 >= WELCOME_LOCALE.allowedAttempts) {
        errorProps = {
            errorMessage: "You've run out of attempts to enter your SIM serial number.",
            errorText: 'Unable to process request',
            context: WELCOME_LOCALE.errorContext.EXCEEDED_ATEMPTS
        };
    }

    if (outageDisplay) {
        errorProps = {
            errorMessage: PPA_OUTAGE_NOTIFICATION_MESSAGE || '',
            errorText: 'Pre-Paid Activation Unavailable'
        };
    }

    const authProps = {
        outageDisplay,
        loginCheckInProgress
    };

    // Analytics data to add 'contact uuid' for logged in user
    const { app: { appData: { customerInfo = {}, cid, address: { customerState } = {}  } } = {} } = props;
    const { contactId = '' } = customerInfo;
    addDataLayerUser({ type: userProfileInfoMeta.USER_CONTACT_UUID, value: contactId, address: {
        stateProvince: customerState }
    });
    isJBHiFi = getBrandDetails().brandName === BRANDS.JBHiFi.name;
    priceRise = simVersion === 'PRE_PRICE_RISE';
    // RENDER
    return (
        <div>
            {cid && <div id="hide-cid">{cid}</div>}
            <GlobalBanner onPage="landing" className="infobanner-landing" />
            {!hasErrorOccurred && !outageDisplay && (
                <div className={`${isNumberChecked ? 'isNumberChecked' : ''}`}>
                    <div className="row">
                        <div className="col-lg-5">
                            {!isEsimActivation && !loggedIn && <Auth {...authProps} />}
                            {showCustomer && !isEsimActivation && (
                                <div>
                                    <TextStyle alias="HeadingC" element="h3" className="mb-2">Welcome back!</TextStyle>
                                </div>
                            )}
                            <TextStyle tabIndex="-1" alias="HeadingA" element="h1" className="mb-2">{headerText}</TextStyle>
                            {renderErrors(errors, props)}
                            {!isEsimActivation && (
                                <Fragment>
                                    <TextStyle alias="TextBodyShort" element="p" className="mb-2">
                                        Enter your SIM serial number located on the back of your SIM kit.
                                    </TextStyle>

                                    <div className="mx-auto sim-kit-image-mobile mt-5xl">
                                        <img
                                            className="sim-kit-image img-fluid"
                                            alt=""
                                            /* eslint-disable global-require */
                                            src={require('../../../assets/img/cardvisualmobile.png')}
                                        /* eslint-enable global-require */
                                        />
                                    </div>
                                    <Field
                                        name="simNumber"
                                        id="simNumber"
                                        component={TextField}
                                        type="text"
                                        label={'SIM serial number'}
                                        onChange={resetErrors}
                                        disabled={disableSimIdField}
                                        aria-required="true"
                                        maxLength="13"
                                        helpText="This is a 13-digit number."
                                        showValidationInline={!simNumberError}
                                        validationInlineVariant="Success"
                                        validationInlineText="That's a valid SIM number."
                                    />
                                </Fragment>
                            )}
                            {!isJBHiFi && isNumberChecked && priceRise && priceRiseMessage()}
                            {(isEsimActivation || isNumberChecked) && areMultipleDevicesAvailable && renderDeviceTypes(deviceTypeLable, props, deviceOffers, isKidsWatchSim)}
                            {(isEsimActivation || selectedDevice) && isDeviceTypeMobile && renderActivationFlowButtons(selectedFlow, simIdIsSerialNumber, yourPhoneNumberLabel, props)}
                            {selectedActivationFlow && selectedActivationFlow === 'portNumber' && renderServiceTransferInfoCard(props)}
                            {/* {showNewNumber && renderNewNumber(msisdn, renderNewNumberNote)} */}

                            {!isEsimActivation && !isNumberChecked && (
                                <SpinningButton
                                    id="chkSimBtn"
                                    onSubmit={handleSubmit(checkSimNumber)}
                                    stylingClass="my-4"
                                    isDisabled={isValidatingNumber || outageDisplay}
                                    isLoading={isValidatingNumber}
                                    buttonText={'Check serial number'}
                                />
                            )}
                            {(isEsimActivation || isNumberChecked) && (
                                <SpinningButton
                                    id="chkSimBtn2"
                                    stylingClass="w-100 my-4"
                                    onSubmit={handleSubmit(validateAndProceed)}
                                    isDisabled={disableCheckButton}
                                    isLoading={isFetchingCustomerData}
                                    buttonText={'Continue'}
                                />
                            )}
                        </div>
                        <div className="col-lg-5 div-image mx-auto sim-image">
                            <img
                                className="sim-kit-image img-fluid hide-in-mobile"
                                alt={'SIM card showing the SIM Serial number located above the barcode'}
                                /* eslint-disable global-require */
                                src={isEsimActivation ? esimImage : cardVisual}
                            /* eslint-enable global-require */
                            />
                        </div>
                    </div>
                    {selectedActivationFlow && selectedActivationFlow === 'portNumber' && showPortingModal && <PPVModal {...props} />}
                </div>
            )}
            {(hasErrorOccurred || errors.numberErrorCode === NETWORK_FAILURE || outageDisplay) && (isEsimActivation ? redirectToMyTelstra() : <FullPageError {...errorProps} />)}
        </div>
    );
};

const priceRiseMessage = () => {

    const titleText = 'Our recharge prices have changed';
    const description = 'This change will be applied from the next time you recharge.';
    const actionText = 'View new recharge options';
    const actionLink = 'https://www.telstra.com.au/consumer-advice/price-changes#pre-paid';

    const newTabForRechargeInfo = () => {
        window.open(actionLink, '_blank');
    };

    return (
        <Spacing bottom="spacing1x" top="spacing4x">
            <MessageSection
                developmentUrl={ableSpriteSheet}
                variant="Information"
                description={description}
                titleText={titleText}
                titleElement="h2"
                actionId="recharge-info"
                actionCallback={newTabForRechargeInfo}
                actionElement="button"
                actionText={actionText}
            />
        </Spacing>
    );
};

const validateAndProceed = (values, dispatch, props) => {
    const {
        appData: {
            uiState: { selectedActivationFlow, selectedDevice }
        },
        deviceData: { deviceOffers = [] },
        actions: { updateStepperCustomData, triggerPortingModal, resetPorting, updateCurrentPlan }
    } = props;

    const selectedOffer = deviceOffers.find((offer) => offer.deviceValue === selectedDevice);
    if (selectedDevice.toLowerCase().includes('broadband')) {
        addDataLayerEventInfo('impression', 'information', 'PPA Service Usage', 'Data only');
    } else {
        addDataLayerEventInfo('impression', 'information', 'PPA Service Usage', 'Calls,text,data');
    }
    updateCurrentPlan(selectedOffer.offers[0].tabName);
    if (!isSelectedDeviceMobile(selectedDevice) || (!isEmpty(selectedActivationFlow) && selectedActivationFlow === 'providedNumber')) {
        nextPage(updateStepperCustomData);
    } else if (selectedActivationFlow && selectedActivationFlow === 'portNumber') {
        resetPorting();
        triggerPortingModal(true);
    }
};

// LOCAL ACTIONS
/**
 * Sim field form validation
 *
 * @param {object} values form values
 * @param {object} props sim invalid error messages
 * @returns
 */
const validate = (values) => {
    const errors = {};

    if (isEmpty(values.simNumber)) {
        errors.simNumber = 'Required field. Please enter the 13-digit SIM serial number (numeric only).';
    } else if (values.simNumber && checkSimPattern(values.simNumber)) {
        errors.simNumber = 'Invalid input. Please enter a valid 13-digit SIM serial number (numeric only).';
    }
    if (isEmpty(values.selectedDevice)) {
        errors.selectedDevice = 'Please select the type of the device this SIM will be used for.';
    }
    if (isEmpty(values.selectedActivationFlow)) {
        errors.selectedActivationFlow = 'Please select type of number you would like to use';
    }
    return errors;
};

const onChange = (values, dispatch, props, previousValues) => {
    if (values.selectedDevice === 'Telstra-Prepaid-Wireless-Broadband') {
        if (previousValues.selectedDevice && values.selectedDevice !== previousValues.selectedDevice) {
            props.clearFields(false, false, 'selectedActivationFlow');
        }
    }
};
/**
 * Form submit function
 *
 * @param {object} values form values
 * @param {function} dispatch dispactcher
 * @param {object} props props to get parent action on valid form submit
 */
const checkSimNumber = (values, dispatch, props) => {
    const { isNumberValid, resetErrors } = props.actions;
    resetErrors();
    isNumberValid(values.simNumber);
};

// CUSTOM TEMPLATE
/**
 * Render device type
 *
 * @param {object} deviceTypeSelect Device select object consiting of default label
 * @param {array} [deviceTypes={}] array of device type to be displayed in select
 * @returns
 */
const renderDeviceTypes = (deviceTypeLable, props, deviceOffers = [], isKidsWatchSim) => {
    const {
        appData: {
            uiState: { userSelectedDevice }
        },
        UpdateDeviceError,
        deviceValidationError = false
    } = props;

    const deviceList = deviceOffers.map((device) => {
        const deviceLabel = device.deviceLabel === 'Mobile Phone' ? 'Calls, text, data' : 'Data only';
        return (
            <Field
                id={device.deviceValue}
                isActive={userSelectedDevice === device.deviceValue}
                radioValue={device.deviceValue}
                label={deviceLabel}
                component={RadioButton}
                updateError={UpdateDeviceError}
            />
        );
    });
    if (isKidsWatchSim) {
        deviceList.push(
            <Field
                id="Watch"
                component={RadioButton}
                updateError={UpdateDeviceError}
                isActive={userSelectedDevice === 'Watch'}
                radioValue="Watch"
                label="Watch"
            />
        );
    }
    deviceValidationError && addDataLayerEventInfo('error', 'inline', 'unavailable', 'Please select an option to continue.');
    return (
        <div className="form-group" id="sim-details" tabIndex="-1" role="radiogroup" aria-atomic="true" aria-live="polite" aria-label={deviceTypeLable} aria-required="true">
            {deviceValidationError && (
                <div className="mbxl">
                    <MessageSection
                        developmentUrl={ableSpriteSheet}
                        variant="Error"
                        titleText="Required field."
                        description="Please select an option to continue." />
                </div>
            )}
            <RadioGroup groupLabel={deviceTypeLable} name="selectedDevice" variant="Comfortable">
                {deviceList}
            </RadioGroup>
        </div>
    );
};

/**
 * Render radio buttons for selecting application flow
 * Option 1 is conditional display based on sim id type (mobile or serial number)
 *
 * @param {object} selectedFlow object with option text
 * @param {bool} simIdIsSerialNumber flag which checks the sim id type
 * @returns
 */
const renderActivationFlowButtons = (selectedFlow, simIdIsSerialNumber, yourPhoneNumberLabel, props) => {
    const { option1: serialNumberText, option2: transferNumberText } = selectedFlow;
    const { label } = props.formData.flowSelectionLabel;
    const {
        appData: {
            uiState: { selectedActivationFlow, isEsimActivation }
        },
        UpdateActivationFlow,
        activationError = false
    } = props;
    return (
        <div id="select-activation-flow-container" tabIndex="-1" className="form-group sim-details-radio-grp" role="radiogroup" aria-atomic="true" aria-live="polite" aria-label={yourPhoneNumberLabel} aria-required="true">
            {activationError && (
                <div className="mbxl">
                    <MessageSection
                        developmentUrl={ableSpriteSheet}
                        variant="Error"
                        titleText="Required field."
                        description="Please select an option to continue." />
                </div>
            )}
            <div id="serviceDetails-mobile-desc">
                <TextStyle alias="Label">{label}</TextStyle>
                <MessageSection
                    className="mt-3 mb-2"
                    developmentUrl={ableSpriteSheet}
                    variant="Information"
                    description={WELCOME_LOCALE.serviceDetails.numberCaptureMessage}
                />
            </div>
            <RadioGroup describedById="serviceDetails-mobile-desc" name="selectedActivationFlow" variant="Comfortable">
                <Field
                    type="radio"
                    id="providerNumber"
                    isActive={selectedActivationFlow === 'providedNumber'}
                    radioValue="providedNumber"
                    label={serialNumberText}
                    component={RadioButton}
                    updateError={UpdateActivationFlow}
                />
                <Field
                    type="radio"
                    id="portNumber"
                    isActive={selectedActivationFlow === 'portNumber'}
                    radioValue="portNumber"
                    label={transferNumberText}
                    component={RadioButton}
                    updateError={UpdateActivationFlow}
                />
            </RadioGroup>
        </div>
    );
};

const renderServiceTransferInfoCard = (props) => {
    const {
        appData: {
            uiState: { isEsimActivation = false }
        },
        formData: {
            portingInfoCard: { label, esimLabel }
        }
    } = props;
    const infoLabel = isEsimActivation ? esimLabel : label;
    return (
        <MessageSection
            className="mt-3"
            variant="Information"
            developmentUrl={ableSpriteSheet}
            description={applyBrandDataInTemplate(infoLabel)} />
    );
}

/**
 * Renders the errors returned from service call
 * Based on app state valiable
 *
 * @param {object} errors
 * @param {object} desc
 * @returns
 */
const renderErrors = (errors, props) => {
    if (errors.hasNumberError) {
        let errorHeading = '';
        let errorText = '';
        const {
            serviceNumber: { completionDate = '' }
        } = props.appData;

        switch (errors.numberErrorCode) {
            case SERVER_ERROR:
                errorText = "We’re experiencing technical difficulties. Please try again later or contact 24x7 Chat'";
                break;
            case PACKAGE_NOT_AVAILABLE:
                errorText = "<p>This SIM serial you've entered is invalid, expired or already exists. Please check the number and try again.<br>Please note, your SIM can take up to 24hrs to activate. You'll be notified by email when your service is active. <br><br><strong>For $2 SIMS</strong><br> If you have an invalid or expired SIM, you can order a new one <a href='https://www.telstra.com.au/mobile-phones/prepaid-mobiles/free-prepaid-multi-fit-sim-starter-kit'> here </a> or request a refund from the place of purchase.</p>";
                break;
            case SIM_IN_USE_ERROR:
                errorHeading = 'This SIM has expired';
                errorText = 'Please try another SIM.';
                break;
            case ORDER_IN_PROGRESS:
                errorHeading = 'This serial number is already in use';
                errorText = "If you've just activated, it might need a few more minutes.";
                break;
            case SIM_ORDER_COMPLETED:
                errorHeading = 'SIM already active';
                errorText = `This SIM has already been activated on ${completionDate}. Check your email for details of that activation. For a new SIM activation, enter a different SIM serial number.`;
                break;
            case SIM_ORDER_INPROGRESS:
                errorHeading = 'SIM activation in progress';
                errorText = `We're already in the process of activating this SIM, submitted on ${completionDate}. Please wait, or for a new activation enter a different SIM serial number.`;
                break;
            case INVALID_SIM:
                errorHeading = 'This is not a Telstra SIM';
                errorText =
                    'This looks like a Boost SIM card.<span class="breakWord"> <span>Please activate your service at</span> ' +
                    '<a class="m-0 p-0" target="_blank" href="https://prepaid.activate.boost.com.au">https://prepaid.activate.boost.com.au</a></span>';
                break;
            case SIM_IS_UNSOLD:
                errorHeading = 'This SIM is not validated.';
                errorText = 'Please try again in 24 hours or return the SIM to place of purchase.';
                break;
            case SIM_PRE_CONDITION_NO_MATCH_ERROR:
            default:
                errorHeading = 'Something went wrong';
                errorText = "We're unable to activate this SIM at the moment.";
                break;
        }
        return (
            <MessageSection
                className="mb-3"
                developmentUrl={ableSpriteSheet}
                variant="Error"
                titleText={Parser(errorHeading)}
                description={Parser(errorText)} />
        );
    }
    return false;
};

const fieldList = ['simNumber', 'deviceType', 'selectedActivationFlow'];

// REDUX-FORM
const ServiceDetailsForm = reduxForm({ form: 'simValidationForm', validate, onChange, destroyOnUnmount: false, onSubmitFail: submitFailure(fieldList) })(ServiceDetails);

// Default values and persisting state
const ConnectedFormAndState = connect((state) => {
    const {
        uiState: { selectedDevice }
    } = state.app.appData;

    // activationFlowFormValue and uiState.selectedActivationFlow are same values
    // this is a one off work around when these two values goes out of sync when
    // selected device is not mobile phone.
    return { initialValues: { selectedDevice } };
})(ServiceDetailsForm);

export default ConnectedFormAndState;
