export const SERVER_ERROR = 'SERVER_ERROR';
export const BLACKLIST_ERROR = 'BLACKLIST_ERROR';
export const UNKNOWN_ERROR = 'UNKNOWN_ERROR';
export const NO_MORE_NUMBERS = 'NO_MORE_NUMBERS';
export const PACKAGE_NOT_FOUND = 'PACKAGE_NOT_FOUND';
export const PACKAGE_NOT_AVAILABLE = 'PACKAGE_NOT_AVAILABLE';
export const SIM_NOT_VALIDATED = 'SIM_NOT_VALIDATED';
export const ORDER_IN_PROGRESS = 'ORDER_IN_PROGRESS';
export const INVALID_SIM = 'INVALID_SIM';
export const SIM_IS_UNSOLD = 'SIM_IS_UNSOLD';
export const NO_OFFERS = 'NO_OFFERS';
export const CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND';
export const CUSTOMER_NOT_AUTHORISED = 'CUSTOMER_NOT_AUTHORISED';
export const MAX_RETRY_FAILURE = 'MAX_RETRY_FAILURE';
export const MULTIPLE_CUSTOMERS_FOUND = 'MULTIPLE_CUSTOMERS_FOUND';
export const INVALID_DOCUMENT = 'INVALID_DOCUMENT';
export const PORTING_FAILED = 'PORTING_FAILED';
export const TERMS_NOT_ACCEPTED = 'TERMS_NOT_ACCEPTED';
export const BAD_REQUEST = 'BAD_REQUEST';
export const ADDRESS_NOT_FOUND = 'ADDRESS_NOT_FOUND';
export const NONPHYSICAL_ADDRESS_ERROR = 'NONPHYSICAL_ADDRESS_ERROR';
export const CANNOT_PORT = 'CANNOT_PORT';
export const BILLPAY_PAYMENT_FALIURE = 'BILLPAY_PAYMENT_FALIURE';
export const BILLPAY_PAYMENT_PREAUTH_ERROR = 'BILLPAY_PAYMENT_PREAUTH_ERROR';
export const VISA_CHECKOUT_PAYMENT_FALIURE = 'VISA_CHECKOUT_PAYMENT_FALIURE';
export const PAYPAL_PAYMENT_FALIURE = 'PAYPAL_PAYMENT_FALIURE';
export const FRAUD_DECLINED_ERROR = 'FRAUD_DECLINED_ERROR';
export const UPDATE_CART_FATAL_ERROR = 'UPDATE_CART_FATAL_ERROR';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const FATAL_ERROR = 'FATAL_ERROR';
export const PAYMENT_VALIDATION_ERROR = 'PAYMENT_VALIDATION_ERROR';
export const PAYMENT_UNEXPECTED_ERROR = 'PAYMENT_UNEXPECTED_ERROR';
export const CLIENT_GATEWAY_NETWORK = 'CLIENT_GATEWAY_NETWORK';
export const EXISTING_CUSTOMER_NOT_AVAILABLE = 'EXISTING_CUSTOMER_NOT_AVAILABLE';
export const REGISTER_CUSTOMER_FALIURE = 'REGISTER_CUSTOMER_FALIURE';
export const NOT_ELIGIBLE_ESIM_FREE_OFFER = 'NOT_ELIGIBLE_ESIM_FREE_OFFER';
export const ESIM_HEALTH_NOT_OK = 'ESIM_HEALTH_NOT_OK';
export const VALIDATION_ERROR = 'VALIDATION_ERROR';
export const SHOPPING_CART_CREATION_ERROR = 'SHOPPING_CART_CREATION_ERROR';
export const HOSTED_FIELDS_FIELDS_INVALID = 'HOSTED_FIELDS_FIELDS_INVALID';
export const SHOPPING_CART_UPDATION_ERROR = 'SHOPPING_CART_UPDATION_ERROR';
export const SIM_IN_USE_ERROR = 'SIM_IN_USE_ERROR';
export const SIM_PRE_CONDITION_NO_MATCH_ERROR = 'SIM_PRE_CONDITION_NO_MATCH_ERROR';
export const MAX_ATTEMPT_ERROR = 'MAX_ATTEMPT_ERROR';
export const SEND_CODE_ERROR = 'SEND_CODE_ERROR';
export const INVALID_CODE_ERROR = 'INVALID_CODE_ERROR';
export const CODE_EXPIRED_ERROR = 'CODE_EXPIRED_ERROR';
export const VALIDATE_CODE_ERROR = 'VALIDATE_CODE_ERROR';
export const MIS_ORDER_INPROGRESS = 'MIS_ORDER_INPROGRESS';
export const PORT_NOT_ALLOWED_ERROR = 'PORT_NOT_ALLOWED_ERROR';
export const PORT_GENERIC_FATAL_ERROR = 'PORT_GENERIC_FATAL_ERROR';
export const TELSTRAPRE_NUMBER_ERROR = 'TELSTRAPRE_NUMBER_ERROR';
export const TELLEGPRE_NUMBER_ERROR = 'TELLEGPRE_NUMBER_ERROR';
export const TELSTRAPOST_NUMBER_ERROR = 'TELSTRAPOST_NUMBER_ERROR';
export const TELLEGPOST_NUMBER_ERROR = 'TELLEGPOST_NUMBER_ERROR';
export const BOOSTLEG_NUMBER_ERROR = 'BOOSTLEG_NUMBER_ERROR';
export const TEM_NUMBER_ERROR = 'TEM_NUMBER_ERROR';
export const SAMEPRE_NUMBER_ERROR = 'SAMEPRE_NUMBER_ERROR';
export const NONTPOST_NUMBER_ERROR = 'NONTPOST_NUMBER_ERROR';
export const SUBMIT_CART_FATAL_REFUND = 'SUBMIT_CART_FATAL_REFUND';
export const SUBMIT_CART_FATAL = 'SUBMIT_CART_FATAL';
export const CREATE_CART_FATAL_ERROR = 'CREATE_CART_FATAL_ERROR';
export const ACTIVATION_FATAL_ERROR = 'ACTIVATION_FATAL_ERROR';
export const ID_UNAVAILABLE = 'ID_UNAVAILABLE';
export const PORTING_DECLINED = 'PORTING_DECLINED';
export const EXCEEDED_ATEMPTS = 'EXCEEDED_ATEMPTS';
export const IDVD_FATAL_MANUAL = 'IDVD_FATAL_MANUAL';
export const IDVD_FATAL_CONNECT_ID = 'IDVD_FATAL_CONNECT_ID';
export const INVALID_PAYPAL_DETAILS = 'INVALID_PAYPAL_DETAILS';
export const TECHNICAL_ERROR = 'TECHNICAL_ERROR';
export const CARD_PAYMENT_REFUND_SUCCESS = 'CARD_PAYMENT_REFUND_SUCCESS';
export const PAYPAL_PAYMENT_REFUND_SUCCESS = 'PAYPAL_PAYMENT_REFUND_SUCCESS';
export const CARD_PAYMENT_REFUND_FAILURE = 'CARD_PAYMENT_REFUND_FAILURE';
export const PAYPAL_PAYMENT_REFUND_FAILURE = 'PAYPAL_PAYMENT_REFUND_FAILURE';
export const ACTIVATION_FAILURE_VALUE_SIM = 'ACTIVATION_FAILURE_VALUE_SIM';
export const SIM_ORDER_COMPLETED = 'SIM_ORDER_COMPLETED';
export const SIM_ORDER_INPROGRESS = 'SIM_ORDER_INPROGRESS';
export const NETWORK_FAILURE = 'NETWORK_FAILURE';
export const SERVICE_ERROR = 'SERVICE_ERROR';

export const RECIEVE_OTC_INIT = 'RECIEVE_OTC_INIT';
export const VALIDATE_OTC_SUCCESS = 'VALIDATE_OTC_SUCCESS';
export const VALIDATE_OTC_ERROR = 'VALIDATE_OTC_ERROR';
export const RESET_OTC_STATE = 'RESET_OTC_STATE';
export const VID_EMAIL_CONFLICT = 'VID_EMAIL_CONFLICT';

export const INVALID_OTC = 'INVALID_OTC';
export const EXPIRED_OTC = 'EXPIRED_OTC';
export const MAX_ATTEMPTS_OTC = 'MAX_ATTEMPTS_OTC';

export const MAX_ATTEMPTS_SEND_OTC = 'MAX_ATTEMPTS_SEND_OTC';
export const SEND_OTC_ERROR = 'SEND_OTC_ERROR';

export const UPDATE_MOBILE_ERROR = 'UPDATE_MOBILE_ERROR';
export const UPDATE_CONTACT_MEDIUM_ERROR = 'UPDATE_CONTACT_MEDIUM_ERROR';
export const UPDATE_BIZ_CONTACT_MEDIUM_ERROR = 'UPDATE_BIZ_CONTACT_MEDIUM_ERROR';
export const ABN_ACN_VALIDATION_REQUEST_ERROR = 'ABN_ACN_VALIDATION_REQUEST_ERROR';
export const CREATE_BUSINESS_ACCOUNT_ERROR = 'CREATE_BUSINESS_ACCOUNT_ERROR';

export const SESSION_EXPIRED = 'SESSION_EXPIRED';

export const errorMapForAnalytics = [
    {
        id: PACKAGE_NOT_AVAILABLE,
        text: 'Invalid serial number'
    },
    {
        id: SIM_IN_USE_ERROR,
        text: 'SIM has expired'
    },
    {
        id: ORDER_IN_PROGRESS,
        text: 'Already active Sim'
    },
    {
        id: SIM_NOT_VALIDATED,
        text: 'This SIM is not validated'
    },
    {
        id: INVALID_SIM,
        text: 'This is not a Telstra SIM'
    },
    {
        id: SIM_IS_UNSOLD,
        text: 'This SIM is not validated'
    },
    {
        id: 'EXCEEDED_ATEMPTS',
        text: 'Customer has entered SIM serial no. incorrectly 5 times'
    },
    {
        id: PAYMENT_ERROR,
        text: 'Payment Error'
    },
    {
        id: INVALID_PAYPAL_DETAILS,
        text: 'PAYPAL details are invallid'
    },
    {
        id: TECHNICAL_ERROR,
        text: 'Technical Error while making payment'
    },
    {
        id: CARD_PAYMENT_REFUND_SUCCESS,
        text: 'Refund successful for card payment'
    },
    {
        id: PAYPAL_PAYMENT_REFUND_SUCCESS,
        text: 'Refund successful for paypal payment'
    },
    {
        id: CARD_PAYMENT_REFUND_FAILURE,
        text: 'Refund failure for card payment'
    },
    {
        id: PAYPAL_PAYMENT_REFUND_FAILURE,
        text: 'Refund failure for paypal payment'
    },
    {
        id: ACTIVATION_FAILURE_VALUE_SIM,
        text: 'Activation failure for value sim'
    },
    {
        id: SIM_ORDER_COMPLETED,
        text: 'SIM already active'
    },
    {
        id: SIM_ORDER_INPROGRESS,
        text: 'SIM activation in progress'
    },
    {
        id: SIM_PRE_CONDITION_NO_MATCH_ERROR,
        text: 'Unable to activate the SIM at the moment'
    }
];
