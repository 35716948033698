import { MessageSection } from '@able/react';
import React from 'react';
import { getAbleSpriteSheet, isIdvModuleThirdParty } from '../../../../adapters/utils';
import { useSelector } from 'react-redux';
import { ConnectIdVerifiedBadge } from '../../../../components/ConnectIdVerifiedBadge';

export default function IdVerifiedMessage({ className = '' }) {

    const verifiedUsingConnectId = useSelector(state => state.yourIdDetails.idvDataApiMeta?.data?.connectId);

    const ableSpriteSheet = getAbleSpriteSheet();
    const isThirdPartIdv = isIdvModuleThirdParty();
    if (isThirdPartIdv && verifiedUsingConnectId) {
        return <ConnectIdVerifiedBadge className={className} />;
    } else {
        return (
            <MessageSection
                className={className}
                developmentUrl={ableSpriteSheet}
                variant="Success"
                description="Your ID has been verified." />
        );
    }
}
