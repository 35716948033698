import {
    DOCUMENT_VALIDATION_ERROR,
    DOCUMENT_VALIDATION_SUCCESS,
    IDENTITY_DETAILS_INIT_DONE,
    IDENTITY_DETAILS_INIT_ERROR,
    LOCK_STATE,
    RESET_ERRORS,
    CUSTOMER_CHECK_DONE,
    CUSTOMER_CHECK_ERROR,
    DOCUMENT_VALIDATION_INIT
} from '../actions';
import { SERVER_ERROR, BAD_REQUEST } from '../../adapters/errorCode';
import { callingModuleMeta } from '../../adapters/constants';
import connectIdvReducer, { initialState as connectIDVState } from './connectIdv.module';

// Reducer
const initialState = {
    loading: { appLoading: true, isModuleLoaded: false, identityValidationComplete: false, lockFields: false, isDocumentValidated: false, isCustomerCheckDone: false, isDocumentValidating: false },
    locale: {},
    errors: { hasError: false, errorCode: null, hasServerError: false },
    bannerImage: null,
    submittedData: {},
    ...connectIDVState
};

const reducer = (state = initialState, action = {}) => {
    // console.log('From identity details reducer: ', action, action.payload ? action.payload: '');
    switch (action.type) {
        case IDENTITY_DETAILS_INIT_DONE:
            return {
                ...state,
                loading: { ...state.loading, appLoading: false, isModuleLoaded: true }
            };
        case LOCK_STATE:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: false, lockFields: true },
                appData: { address: { lockAddressState: true } }
            };
        case DOCUMENT_VALIDATION_INIT:
            return {
                ...state,
                loading: { ...state.loading, isDocumentValidating: true },
                submittedData: action.data
            };

        case DOCUMENT_VALIDATION_SUCCESS: {
            return {
                ...state,
                loading: { ...state.loading, formProcessed: true, isDocumentValidated: true, isDocumentValidating: false }
            };
        }

        case DOCUMENT_VALIDATION_ERROR:
            return {
                ...state,
                errors: { ...state.errors, hasError: true, errorCode: action.errorCode, hasServerError: action.errorCode === SERVER_ERROR },
                loading: { ...state.loading, formProcessed: false, isDocumentValidating: false }
            };

        case RESET_ERRORS:
            return {
                ...state,
                errors: initialState.errors
            };

        case CUSTOMER_CHECK_DONE:
            return {
                ...state,
                loading: { ...state.loading, formProcessed: true, isCustomerCheckDone: true }
            };

        case CUSTOMER_CHECK_ERROR: {
            if (action.callingModule === callingModuleMeta.customerDetails) {
                return {
                    ...state,
                    app: { ...state.app, hasErrorOccurred: true },
                    errors: { ...state.errors, hasError: true, errorCode: action.errorCode, hasServerError: action.errorCode === SERVER_ERROR || action.errorCode === BAD_REQUEST },
                    loading: { ...state.loading, formProcessed: true, isCustomerCheckDone: true, lockFields: action.errorCode === SERVER_ERROR || action.errorCode === BAD_REQUEST }
                };
            }
            return state;
        }

        default:
            return connectIdvReducer(state, action);
    }
};

export default reducer;

// Action Creators
/**
 * Init module
 *
 */
export const moduleInit = () => {
    return (dispatch) => moduleInitialised(dispatch);
};

/**
 * Returns dipatch object when page is initialized
 *
 * @param {any} data
 * @returns
 */
export const moduleInitialised = (dispatch) => {
    dispatch({ type: IDENTITY_DETAILS_INIT_DONE });
};

/**
 * Returns dispatch object when page load fails
 *
 * @export
 * @param {any} httpStatus
 * @returns
 */
export function moduleInitialisationError(httpStatus) {
    return { type: IDENTITY_DETAILS_INIT_ERROR, data: httpStatus };
}

/**
 * return dispatch object to
 * resets the module errors
 *
 * @export
 * @returns
 */
export function resetErrors() {
    return { type: RESET_ERRORS };
}

/**
 * return dispatch object to
 * lock the persistant form state.
 *
 * @export
 * @returns
 */
export function lockState() {
    return { type: LOCK_STATE };
}
