import * as client from '../../adapters/client';
import { EXCEEDED_ATEMPTS, SERVER_ERROR, IDVD_FATAL_MANUAL, IDVD_FATAL_CONNECT_ID, SESSION_EXPIRED } from '../../adapters/errorCode';
import { persistor } from "../../store";
import { setFullPageErrorAppFlag } from '../app/actions';
import store from '../../store';

const REDIRECT_TO_IDV = 'REDIRECT_TO_IDV';
const REDIRECTED_FROM_IDV = 'REDIRECTED_FROM_IDV';
const GET_IDV_MAGIC_URL_START = 'GET_IDV_MAGIC_URL_START';
const GET_IDV_MAGIC_URL_FAILED = 'GET_IDV_MAGIC_URL_FAILED';
const GET_IDV_MAGIC_URL_SUCCESS = 'GET_IDV_MAGIC_URL_SUCCESS';
const GET_IDV_DATA_START = 'GET_IDV_DATA_START';
const GET_IDV_DATA_FAILED = 'GET_IDV_DATA_FAILED';
const GET_IDV_DATA_SUCCESS = 'GET_IDV_DATA_SUCCESS';

export const initialState = {
    userIdVerified: false,
    idVerificationInProgress: false,
    idvAttempts: 0,
    magicUrlApiMeta: {
        loading: false,
        data: {},
        error: false,
        errorCode: null
    },
    idvDataApiMeta: {
        loading: false,
        data: {},
        error: false,
        errorCode: null
    },
    isSoftStopError: false
};

export default (state, action = {}) => {
    switch (action.type) {

        case GET_IDV_MAGIC_URL_START:
            return { ...state, magicUrlApiMeta: { loading: true, data: {} } };
        case GET_IDV_MAGIC_URL_SUCCESS:
            return { ...state, magicUrlApiMeta: { data: action.data } };
        case GET_IDV_MAGIC_URL_FAILED:
            return { ...state, magicUrlApiMeta: { error: true, errorCode: action.errorCode, data: {} } };

        case GET_IDV_DATA_START:
            return { ...state, idvDataApiMeta: { loading: true, data: {} } };
        case GET_IDV_DATA_SUCCESS:
            return { ...state, idvDataApiMeta: { data: action.data }, userIdVerified: true };
        case GET_IDV_DATA_FAILED:
            return { ...state, idvDataApiMeta: { error: true, errorCode: action.errorCode, data: {} }, isSoftStopError: action.isSoftStopError };

        case REDIRECT_TO_IDV:
            return { ...state, idvAttempts: state.idvAttempts + 1, idVerificationInProgress: true };
        case REDIRECTED_FROM_IDV:
            return {
                ...state,
                idVerificationInProgress: false
            };

        default:
            break;
    }
    return state;
};

const processMagicUrlResponse = response => {
    let errorCode = '';
    if (response.status?.toLowerCase() !== 'success') {
        errorCode = response.status;
        if (response.status === 'IDVD-FATAL-ATEX') {
            errorCode = EXCEEDED_ATEMPTS;
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: response.data };
}

const fetchMagicUrlSuccess = (response) => {
    const { hasError, errorCode, data } = processMagicUrlResponse(response);
    if (hasError) {
        return fetchMagicUrlFailed(errorCode);
    }
    return dispatch => {
        dispatch({
            type: GET_IDV_MAGIC_URL_SUCCESS,
            data
        });
        dispatch(startIDVFlow(data.url));
    }
}

const fetchMagicUrlFailed = statusCode => {
    let errorCode = SERVER_ERROR;
    return dispatch => {
        dispatch(setFullPageErrorAppFlag());
        dispatch({
            type: GET_IDV_MAGIC_URL_FAILED,
            errorCode
        });
    }
}

export const fetchMagicUrl = () => {
    return (dispatch) => {
        dispatch({ type: GET_IDV_MAGIC_URL_START });
        client.fetchIdvMagicUrl(dispatch, fetchMagicUrlSuccess, fetchMagicUrlFailed);
    };
};

export const startIDVFlow = (url) => {
    return dispatch => {
        dispatch({ type: REDIRECT_TO_IDV });
        persistor.persist();
        setTimeout(() => {
            window.location.href = url;
        }, 200);
    };
}

export const endIDVFlow = () => {
    return dispatch => {
        persistor.purge();
        dispatch({ type: REDIRECTED_FROM_IDV });
    };
}

export const resetAndReload = () => {
    return dispatch => {
        persistor.purge();
        window.location.reload();
        dispatch({ type: '' });
    };
}

const processIdvDataResponse = response => {
    const { loggedIn = false } = store.getState().app?.appData?.uiState || {};
    let errorCode = '';
    let hardStop = false;
    let isSoftStopError = false;
    let retry = false;
    if (response.status?.toLowerCase() !== 'success') {
        if (response.status === 'IDVD-FATAL-ATEX') {
            errorCode = EXCEEDED_ATEMPTS;
            hardStop = true;
        } else if (response.status === 'IDVD-EX-EXPIRED') {
            errorCode = SESSION_EXPIRED;
            retry = true;
        } else if (response.status === 'IDVD-FATAL-M' && loggedIn) {
            errorCode = IDVD_FATAL_MANUAL;
            hardStop = true;
        } else if (response.status === 'IDVD-FATAL-C' && loggedIn) {
            errorCode = IDVD_FATAL_CONNECT_ID;
            isSoftStopError = true;
        } else {
            errorCode = response.status;
        }
    }
    return { hasError: Boolean(errorCode.length), errorCode, data: response.data, hardStop, isSoftStopError, retry };
}

const getValidatedIdvDataSuccess = (cb, response) => {
    const { hasError, errorCode, hardStop, data, isSoftStopError, retry } = processIdvDataResponse(response);
    if (hasError) {
        if (retry) {
            return fetchMagicUrl();
        }
        return getValidatedIdvDataFailed(errorCode, hardStop, isSoftStopError);
    }
    cb?.(data);
    return {
        type: GET_IDV_DATA_SUCCESS,
        data
    };
}

const getValidatedIdvDataFailed = (statusCode, hardStop, isSoftStopError) => {
    let errorCode;
    if (typeof statusCode === 'string') {
        errorCode = statusCode;
    } else {
        errorCode = SERVER_ERROR;
        hardStop = true;
    }
    return dispatch => {
        if (hardStop) {
            isSoftStopError = false;
            dispatch(setFullPageErrorAppFlag());
        }
        dispatch({
            type: GET_IDV_DATA_FAILED,
            errorCode,
            isSoftStopError
        });
    }
}

export const getValidatedIdvData = (successCB) => {
    return (dispatch) => {
        dispatch({ type: GET_IDV_DATA_START });
        client.fetchValidatedIdvData(dispatch, getValidatedIdvDataSuccess.bind(null, successCB), getValidatedIdvDataFailed);
    };
};
